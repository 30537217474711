.image-text-popup {
	width: 95%;
	max-width: 1200px;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: #fff;
	border-radius: 20px;
	display: none;
	z-index: 9999;
	-webkit-box-shadow: 0px 3px 22px -1px rgba(0,0,0,0.29);
	box-shadow: 0px 3px 22px -1px rgba(0,0,0,0.29);

	&.open {
		display: flex;
	}

	@media screen and (max-width: 868px) {
		flex-direction: column;
		max-width: 620px;
	}

	&__image {
		width: 50%;
		position: relative;

		@media screen and (max-width: 868px) {
			width: 100%;
		}

		img {
			max-width: 100%;
			display: block;
			object-fit: cover;
			position: absolute;
			width: 100%;
			height: 100%;
			border-top-left-radius: 20px;
			border-bottom-left-radius: 20px;

			@media screen and (max-width: 868px) {
				object-fit: contain;
				position: relative;
				width: auto;
				height: auto;
				border-bottom-left-radius: 0px;
				border-top-right-radius: 20px;
			}
		}
	}

	&__content {
		width: 50%;
		padding: 127px 5%;
		text-align: left;
		position: relative;

		@media screen and (max-width: 868px) {
			width: 100%;
			padding: 48px 5%;
		}

		h3 {
			font-size: $textL;
			color: #000;
			margin-bottom: 24px;
			font-weight: 600;
			cursor: auto;

			&:hover {
				opacity: 1;
			}
		}

		p {
			line-height: 1.7;
		}

		.button {
			margin-top: 24px;
		}
	}

	&__close-btn {
		width: 32px;
		height: 32px;
		border-radius: 100%;
		border: 1px solid black;
		font-size: 20px;
		text-align: center;
		line-height: 29px;
		display: block;
		position: absolute;
		top: 20px;
		right: 20px;
		cursor: pointer;
	}
}
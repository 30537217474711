.site-footer {
	width: 100%;
	background: $marknadsBlue;
	padding: 100px 20px 50px 20px;

	&__inner {
		width: 100%;
		max-width: 1120px;
		margin: 0 auto;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: flex-start;

		@media screen and (max-width: 868px) {
			flex-direction: column;
		}
	}

	&__menu {
		width: 80%;

		@media screen and (max-width: 868px) {
			width: 100%;
		}

		.menu {
			display: flex;
			width: 100%;
			flex-wrap: wrap;

			@media screen and (max-width: 620px) {
				flex-direction: column;
			}

			a {
				transition: opacity 0.2s ease-in-out;

				&:hover {
					opacity: 0.6;
				}
			}

			li {
				width: calc(100% / 3 - 32px);
				list-style: none;
				margin-bottom: 30px;
				margin-right: 32px;

				@media screen and (max-width: 868px) {
					width: calc(100% / 2 - 32px);
				}

				@media screen and (max-width: 620px) {
					width: 100%;
					margin-right: 0;
				}

				a {
					font-size: $textM;
					color: #fff;
					font-weight: 600;
					margin-bottom: 24px;
					display: block;
				}
			}

			.sub-menu {
				li {
					width: 100%;
					margin-right: 0;
					margin-bottom: 16px;

					a {
						font-size: $textS;
						font-weight: 600;
						margin-bottom: 0;
					}
				}
			}
		}
	}

	&__column {
		// width: 20%;

		@media screen and (max-width: 868px) {
			width: 100%;
			padding-bottom: 40px;
		}

		&-list {
			margin-top: 24px;

			li {
				list-style: none;
				display: flex;
				margin-bottom: 8px;

				a {
					font-size: $textXS;
					color: #fff;
					transition: opacity 0.2s ease-in-out;

					&:hover {
						opacity: 0.6;
					}
				}

				img {
					margin-right: 10px;
				}
			}
		}

		&-inner {
			color: #fff;
		}

		&-title {
			font-size: $textM;
			margin: 0 0 24px 0;
			font-weight: 600;
		}

		&-text {
			font-size: 16px;
			line-height: 22px;

			a {
				display: block;
				color: #fff;
				margin: 10px 0;
				transition: opacity 0.1s ease-in-out;

				&:hover {
					opacity: 0.6;
				}
			}
		}
	}

	&__social {
		display: flex;
		flex-direction: row;
		align-items: center;

		li {
			list-style: none;
			margin-right: 10px;
			transition: transform 0.1s ease-in-out;

			&:hover {
				transform: translateY(-2px);
			}

			a {
				font-size: 20px;
				color: #fff;

				i {
					width: 20px;
					height: 20px;
					padding: 2px;
					background: #fff;
					color: $marknadsBlue;
				}
			}
		}
	}

	.footer-links {
		ul {
			list-style: none;

			li {
				width: 100%;
				margin-right: 0;
				margin-bottom: 16px;
				
	
				a {
					font-size: $textS;
					font-weight: 600;
					margin-bottom: 0;
					color: #ffffff;
					transition: opacity 0.2s ease-in-out;

					&:hover {
						opacity: 0.6;
					}
				}
			}
		}
	
	}
	
	.iso-icon {
		display: flex;
		flex-direction: row;
		gap: 10px;

		img {
			max-width: 120px;
		}
	}
}

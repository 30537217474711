.tracks-block {
	width: 100%;
	background-color: $lighestGray;
	padding-top: 117px;
	padding-bottom: 117px;

	@media screen and (max-width: 500px) {
		padding-top: 42px;
		padding-bottom: 42px;
	}

	&__inner {
		width: 100%;
		max-width: 1280px;
		margin: 0 auto;
		text-align: center;

		& .heading-xl {
			max-width: 520px;
			margin: 0 auto 24px auto;
		}
	}

	&__columns {
		display: flex;
		flex-wrap: wrap;
		margin-top: 80px;

		@media screen and (max-width: 868px) {
			justify-content: center;
		}

		@media screen and (max-width: 500px) {
			margin-top: 50px;
		}
	}

	&__column {
		text-align: left;
		width: calc(100% / 4 - 44px);
		max-width: 275px;
		margin: 30px 22px 80px 22px;
		padding: 44px 32px;
		background-color: #fff;
		border-radius: 8px;

		@media screen and (max-width: 1024px) {
			width: calc(100% / 3 - 44px);
			margin-bottom: 30px;
		}

		@media screen and (max-width: 768px) {
			width: calc(100% / 2 - 44px);
			margin-bottom: 30px;
		}

		@media screen and (max-width: 500px) {
			width: 100%;
			max-width: none;
			margin: 0 0 48px 0;
		}

		& .heading-md {
			margin-bottom: 10px;
			color: $marknadsBlue;
		}

		.wysiwyg {
			p {
				margin: 10px 0 0 0;
			}

			ul,
			ol {
				margin: 0;
			}
		}
	}
}

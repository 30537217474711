.timeline {
	width: 100%;
	background: $lightBlue;
	padding-top: 110px;
	padding-bottom: 110px;

	@media screen and (max-width: 500px) {
		padding-top: 42px;
		padding-bottom: 42px;
	}

	&__wrap {
		width: 100%;
		max-width: 1100px;
		margin: 0 auto;
		padding: 0 24px;
	}

	&__decade {
		width: 100%;
		display: flex;
		align-items: flex-end;

		& .heading-xxl {
			order: 2;
			max-width: 200px;
			padding: 0 0 0 80px;
			top: -56px;

			color: $blackAsh;
			position: relative;

			@media screen and (max-width: 620px) {
				display: none;
			}
		}

		&-years {
			order: 1;
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: flex-end;
		}

		&-year {
			width: 50%;
			display: flex;
			flex-direction: column;
			position: relative;
			margin: 0 0 38px 0;

			@media screen and (max-width: 768px) {
				width: 100%;
			}

			&:nth-child(odd) {
				width: 100%;
			}

			&-dot {
				width: 16px;
				height: 16px;
				border-radius: 100%;
				background: $marknadsBlue;

				&--filler {
					margin-left: auto;
				}
			}

			&-line {
				width: 100%;
				display: flex;
				position: relative;
				align-items: center;
				justify-content: space-between;

				& .heading-lg {
					background: $lightBlue;
					position: relative;
					z-index: 1;
					padding: 0 24px 0 0;
					color: $marknadsBlue;
				}

				&:before {
					content: '';
					width: 100%;
					height: 2px;
					background: $marknadsBlue;
					display: block;
					position: absolute;
					z-index: 0;
				}
			}

			& .body-sm {
				max-width: 260px;
				color: $marknadsBlue;
				margin-top: 12px;
			}
		}
	}
}

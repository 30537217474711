.cta-columns {
	padding-top: 84px;
	padding-bottom: 84px;

	@media screen and (max-width: 868px) {
		padding-top: 48px;
		padding-bottom: 48px;
	}

	& .heading-xl {
		width: 100%;
		margin: 0 auto 48px auto;
	}

	&__wrap {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
	}

	&__column {
		display: flex;
		flex-direction: column;
		width: calc(100% / 3 - 32px);
		margin-right: 32px;
		margin-bottom: 62px;

		@media screen and (max-width: 868px) {
			width: calc(100% / 2 - 16px);
			margin-bottom: 32px;
			margin-right: 16px;
		}

		@media screen and (max-width: 600px) {
			width: 100%;
			margin-bottom: 42px;
			margin-right: 0;

			&:last-of-type {
				margin-bottom: 0;
			}
		}

		&-img {
			margin-top: auto;
		}

		& .heading-lg {
			margin-bottom: 16px;
		}

		& .body {
			margin-bottom: 24px;
		}

		.button {
			margin-top: 36px;
		}

		&-img {
			img {
				max-width: 100%;
			}
		}
	}
}

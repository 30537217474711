.layout-track_columns_block {
	width: 100%;
	padding: 100px 0;
	margin: 0 auto;
	background-color: rgba($marknadsBlue, 0.3);
}

.track-columns {
	width: 100%;
	max-width: 1400px;
	margin: 0 auto;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;

	@include below(1024px) {
		margin: 50px auto;
	}

	&__column {
		width: calc(100% / 3 - 1px);
		max-width: calc(100% / 3);
		padding: 15px;

		@include below(769px) {
			width: 50%;
			max-width: 50%;
		}

		@include below(500px) {
			width: 100%;
			max-width: 100%;
		}

		&-inner {
			// -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.04);
			// -moz-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.04);
			// box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.04);
		}

		&-content {
			padding: 0 0 30px 0;
			color: #fff;
		}

		&-image {
			max-width: 100%;
			display: block;
			transition: opacity 0.2s ease-in-out;
		}

		&-title {
			font-size: 28px;
			line-height: 34px;
			font-family: 'bebas-neue', sans-serif;
			text-transform: uppercase;
			margin: 0 0 15px 0;
		}

		&-text {
			font-size: 16px;
			line-height: 22px;
		}

		&-link {
			display: inline-block;
			margin: 40px 0 0 0;
			color: #ffff;
			padding: 10px 20px;
			font-size: 16px;
			background: rgba($marknadsBlue, 0.5);
			text-align: center;
			flex-direction: 20px;
			// font-family: 'bebas-neue', sans-serif;
			font-family: 'Aller', sans-serif;
			transition: background 0.2s ease-in-out;

			&:hover {
				background: rgba($marknadsBlue, 1);
			}
		}
	}

	a {
		color: #ffff;
	}
}

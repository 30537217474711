.text-image-block {
	margin-top: 88px;
	margin-bottom: 88px;
	display: flex;
	justify-content: space-between;
    align-items: center;

	@media screen and (max-width: 768px) {
		flex-direction: column;
		margin-top: 48px;
		margin-bottom: 48px;
	}

	&__content {
		width: 50%;
		max-width: 520px;
		padding: 5% 5% 5% 0;

		h2 {
			font-size: $textL;
			margin-bottom: 24px;
			font-weight: 600;
		}

		@media screen and (max-width: 768px) {
			width: 100%;
			padding: 0 0 5% 0;
			max-width: none;
		}
	}

	&__image {
		width: 50%;

		@media screen and (max-width: 768px) {
			width: 100%;
		}

		img {
			max-width: 100%;
			display: block;
		}
	}
}
@media screen and (min-width: 768px) {

	.row-reverse .text-image-block__content {
		width: 50%;
		max-width: 520px;
		padding: 5% 0% 5% 5%;
	}
}
.white-text-block {
	width: 100%;
	background: #fff;
	margin-top: 96px;
	margin-bottom: 96px;
	max-width: 756px;
	margin-left: auto;
	margin-right: auto;

	@media screen and (max-width: 640px) {
		padding-left: 16px;
		padding-right: 16px;
		margin-top: 68px;
		margin-bottom: 68px;
	}

	& .heading-xl {
		margin-bottom: 24px;
	}

	& .wysiwyg {
	}
}

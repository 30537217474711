.site-navigation {
	width: 100%;
	background: $marknadsBlue;
	padding-left: 24px;
	padding-right: 24px;
	padding-top: 9px;
	padding-bottom: 9px;
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: space-between;
	z-index: 9999;

	&__logo {
		max-width: 150px;
	}

	&__nav {
		.menu-main-menu-container {
			@media screen and (max-width: 1281px) {
				display: none;
			}
		}
	}

	.burger-wrap {
		right: -5px;
		top: 24px;
	}
}

.site-navigation__nav .menu {
	display: flex;
	list-style: none;
}

.site-navigation__nav .menu .menu-item {
	position: relative;
	margin-right: 28px;

	& > a {
		color: #fff;
		display: flex;
		align-items: center;
		margin-bottom: 12px;
		transition: opacity 0.2s ease-in-out;
	}

	&:last-of-type {
		margin-right: 0;
	}

	&.menu-item-has-children > a {
		cursor: pointer;

		&:after {
			content: '';
			color: #000;
			width: 15px;
			height: 8px;
			float: right;
			margin-left: 5px;
			display: block;
			background-repeat: no-repeat;
			background-position: center;
			background-image: url('../img/small-arrow-down.svg');
		}
	}

	&:hover {
		& > a {
			opacity: 0.7;
		}

		.sub-menu {
			opacity: 1;
			pointer-events: all;
		}
	}
}

.site-navigation__nav .menu .menu-item .sub-menu {
	position: absolute;
	background-color: #fff;
	list-style: none;
	box-shadow: 0px 10px 16px -2px rgba(0, 0, 0, 0.2);
	z-index: 1;
	opacity: 0;
	padding: 38px 38px 26px 38px;
	text-align: left;
	pointer-events: none;
	border-radius: 24px;
	white-space: nowrap;

	.menu-item {
		width: 100%;
		margin-right: 0;
		line-height: 1rem !important;
		margin-bottom: 18px;

		&.current_page_item {
		}

		a {
			color: #000;
			font-weight: 600;
		}

		&:last-of-type {
		}

		&:after {
		}

		&:hover {
		}
	}

	.sub-menu {
		background-color: none;
		box-shadow: none;
		position: relative;
		padding: 0;
		border-radius: none;
		list-style: disc;
		padding: 0 24px;

		.menu-item {
			margin-bottom: 0;
		}

		a {
			color: #000;
			font-weight: 400;
		}
	}
}

.site-navigation__mobile {
	width: 100%;
	height: 100%;
	max-width: 300px;
	position: fixed;
	top: 0;
	right: 0;
	z-index: 10;
	background: $marknadsBlue;
	padding: 20px 24px 60px 24px;
	transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
	opacity: 0;
	visibility: hidden;
	pointer-events: none;
	transform: translateX(100%);

	@media screen and (max-width: 640px) {
		max-width: none;
	}

	&.open {
		opacity: 1;
		visibility: visible;
		pointer-events: auto;
		transform: translateX(0);
	}

	&-header {
		width: 100%;
		display: flex;
		justify-content: space-between;
		margin: 14px 0 60px 0;
	}

	&-close {
		color: #fff;
		font-size: 20px;
		cursor: pointer;
	}

	&-back-button {
		width: 15px;
		height: 8px;
		display: block;
		background-repeat: no-repeat;
		background-image: url('../img/small-arrow-down.svg');
		transform: rotate(90deg);
		transform-origin: left;
		cursor: pointer;
		opacity: 0;
		transition: opacity 0.2s ease-in-out;

		&.show {
			opacity: 1;
		}
	}

	.menu {
		overflow: hidden;
		position: relative;

		.menu-item {
			list-style: none;
			padding: 0 0 16px 0;
			margin: 0 0 28px 0;
			border-bottom: 1px solid #fff;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.sub-button {
				width: 30px;
				height: 14px;
				top: 2px;
				position: relative;
				display: block;
				background-repeat: no-repeat;
				background-size: contain;
				background-position: right;
				display: inline-block;
				cursor: pointer;
				background-image: url('../img/white-right-arrow.svg');
			}

			a {
				font-size: $textM;
				color: #fff;
				font-weight: 600;
			}
		}

		.menu-item-has-children {
		}

		.sub-menu {
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			z-index: 1;
			background: $marknadsBlue;
			transform: translateX(100%);
			transition: transform 0.2s ease-in-out;

			&.open {
				transform: translateX(0);
			}

			.sub-button {
				display: none;
			}

			.sub-menu {
				position: relative;
				height: auto;
				transform: translateX(0);

				.menu-item {
					margin: 12px 0;
					padding: 0 0 0 8px;
					display: flex;
					align-items: center;
					justify-content: flex-start;

					&:before {
						content: '';
						width: 3px;
						height: 3px;
						margin-right: 8px;
						border-radius: 100%;
						background: #fff;
						display: inline-block;
					}

					a {
						font-weight: 400;
						font-size: $textS;
					}
				}
			}

			.menu-item {
				list-style: none;
				padding: 0;
				margin: 0 0 16px 0;
				border-bottom: none;
				display: block;

				a {
					font-weight: 600;
					font-size: $textS;
				}

				&:after {
					display: none;
				}
			}
		}
	}
}

.heading {
	&-xxl {
		font-family: $familjen;
		color: $white;

		font-weight: 700;
		font-size: 58px;
		line-height: 100%;

		@media screen and (max-width: 960px) {
			font-size: 28px;
			line-height: 33.6px;
		}
	}

	&-xl {
		font-family: $familjen;
		font-weight: 700;
		font-size: 40px;
		line-height: 120%;

		// @media screen and (max-width: 960px) {
		// 	font-size: $textM;
		// }
	}

	&-lg {
		font-family: $familjen;
		font-weight: 700;
		font-size: 28px;
		line-height: 120%;
	}

	&-md {
		font-family: $familjen;
		font-weight: 500;
		font-size: 20px;
		line-height: 125%;
	}
	&-sm {
		font-family: $familjen;
		font-weight: 500;
		font-size: 16px;
		line-height: 125%;
	}
}

.body {
	font-family: $familjen;
	font-style: normal;
	font-weight: 400;
	color: $almostBlack;

	/* body default size */
	font-size: 16px;
	line-height: 165%;

	&-lg {
		font-family: $familjen;
		font-size: 20px;
		line-height: 150%;
		@media screen and (max-width: 960px) {
			font-size: 16px;
		}
	}

	&-sm {
		font-family: $familjen;
		font-size: 14px;
		line-height: 165%;
	}

	&-xs {
		font-family: $familjen;
		font-size: 12px;
		line-height: 150%;
	}
}

.text-white {
	color: #fff;
}
.text-black {
	color: $almostBlack;
}
.text-ash {
	color: $blackAsh;
}
.text-blue {
	color: $marknadsBlue;
}

.text-blue-200 {
	color: $lightBlue;
}

.text-columns-block {
	width: 100%;
	padding-top: 110px;
	padding-bottom: 110px;
	background: $marknadsBlue;
	color: #fff;
	position: relative;

	@media screen and (max-width: 500px) {
		padding-top: 42px;
		padding-bottom: 42px;
	}

	& .background-container {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;

		left: 0;
		z-index: 0;
	}

	& .background {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	&--lightBlue {
		background: $lightBlue;
		color: #000;
	}

	&__inner {
		position: relative;
		z-index: 1;
		width: 100%;
		text-align: center;

		& .heading-xl {
			max-width: 520px;
			margin: 0 auto 24px auto;
		}

		& .heading-sm {
			max-width: 520px;
			margin: 0 auto;
		}

		.button {
			margin-top: 76px;
		}
	}

	&__columns {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin-top: 80px;

		@media screen and (max-width: 500px) {
			margin-top: 50px;
		}
	}

	&__col {
		text-align: center;
		width: calc(100% / 4 - 44px);
		max-width: 212px;
		margin: 22px;
		padding: 44px 32px;
		background-color: #fff;
		border-radius: 8px;

		@media screen and (max-width: 1024px) {
			max-width: none;
			width: calc(100% / 3 - 44px);
		}

		@media screen and (max-width: 868px) {
			max-width: none;
			width: calc(100% / 2 - 44px);
		}

		@media screen and (max-width: 500px) {
			width: 100%;
			margin: 22px 0;
		}

		img {
			display: block;
			margin: 0 auto 16px auto;
		}

		& .heading-md {
			color: #272a3a;
		}

		& .body-sm {
			margin-top: 16px;
			color: #272a3a;
		}
	}
}

.big-text-column-rows {
	width: 100%;
	padding: 78px 0;
	// background: $lightGray;

	@media screen and (max-width: 500px) {
		padding: 68px 0;
	}

	& .heading-xl {
		margin-bottom: 48px;

		@media screen and (max-width: 500px) {
			// font-size: $textL;
		}
	}

	&__row {
		// &-title {
		// 	font-size: $textM;
		// 	font-weight: 600;
		// 	margin-bottom: 44px;
		// 	line-height: 1.5;
		// }
	}

	&__columns {
		display: flex;
		flex-direction: column;
	}

	&__column {
		width: 100%;
		margin: 0 30px 48px 0;
		padding: 0 0 16px 0;
		border-bottom: 1px solid $asfalt;

		&:hover {
			svg {
				transform: translateX(-3px);
			}
		}

		svg {
			margin-left: 10px;
			transition: transform 0.1s ease-in-out;
		}

		.heading-md {
			color: $marknadsBlue;
			margin-bottom: 23px;
			display: flex;
			justify-content: space-between;
		}

		& .body-sm {
			max-width: 620px;
			color: #272a3a;

			p {
				// font-size: 14px;
				margin-bottom: 16px;
			}

			a {
				color: $marknadsBlue;
				display: block;
				transition: opacity 0.2s ease-in-out;

				&:hover {
					opacity: 0.6;
				}
			}
		}
	}
}

.image-text-block {
	width: 100%;
	background: #fff;
	display: flex;
	padding: 0;
	min-height: 612px;

	@media screen and (max-width: 868px) {
		min-height: none;
		flex-direction: column;
	}

	&__image {
		width: 50%;
		position: relative;

		@media screen and (max-width: 868px) {
			width: 100%;
		}

		img {
			max-width: 100%;
			display: block;
			object-fit: cover;
			position: absolute;
			width: 100%;
			height: 100%;

			@media screen and (max-width: 868px) {
				object-fit: contain;
				position: relative;
				width: auto;
				height: auto;
			}
		}
	}

	&__inner {
		width: 50%;
		padding: 116px 5%;
		display: flex;
		align-items: center;

		@media screen and (max-width: 868px) {
			width: 100%;
			padding: 48px 24px;
			justify-content: flex-start;
		}
	}

	&__content {
		width: 100%;
		max-width: 620px;

		&.has-subtitle {
			h2 {
				margin-bottom: 26px;

				@media screen and (max-width: 640px) {
					margin-bottom: 16px;
				}
			}
		}

		h2 {
			font-size: $textXL;
			margin-bottom: 32px;

			@media screen and (max-width: 640px) {
				font-size: $textL;
				margin-bottom: 24px;
			}
		}

		h3 {
			font-size: $textM;
			line-height: 1.8rem;
			font-weight: 600;
			margin-bottom: 24px;
		}

		p {
			font-size: $textS;
			line-height: 1.5rem;
		}
	}

	&__text {
		p {
			margin: 0 0 20px 0;
		}
	}

	.button {
		margin-top: 32px;

		@media screen and (max-width: 640px) {
			margin-top: 24px;
		}
	}
}
.team-block {
	width: 100%;
	padding-top: 110px;
	padding-bottom: 110px;
	background: #fff;
	color: #000;

	@media screen and (max-width: 500px) {
		padding-top: 42px;
		padding-bottom: 42px;
	}

	&__inner {
		width: 100%;

		& .heading-xl {
			margin: 0 auto 48px auto;
		}
	}

	&__columns {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
	}

	&__column {
		width: calc(100% / 4 - 30px);
		margin: 0 30px 30px 0;

		@media screen and (max-width: 868px) {
			max-width: none;
			width: calc(100% / 3 - 40px);
		}

		@media screen and (max-width: 640px) {
			width: calc(100% / 2 - 40px);
		}

		@media screen and (max-width: 500px) {
			width: 100%;
			margin: 30px 0 20px 0;
		}

		&-image {
			img {
				max-width: 100%;
				display: block;
			}
		}

		& .heading-md {
			margin: 24px 0 16px 0;
		}

		& .body {
			margin-bottom: 16px;
		}

		ul {
			li {
				margin-bottom: 8px;
				list-style: none;

				a {
					color: $primary2;
				}
			}
		}
	}
}

.big-text-image-column {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: calc(100% / 2 - 32px);
	margin-right: 32px;
	margin-bottom: 50px;

	@media screen and (max-width: 600px) {
		width: 100%;
		margin-bottom: 42px;
		margin-right: 0;

		&:last-of-type {
			margin-bottom: 0;
		}
	}
	&__column-img {
		margin-top: auto;

		img {
			max-width: 100%;
		}
	}

	.heading-md {
		margin-bottom: 16px;
	}

	.body {
		margin-bottom: 36px;
	}

	.button {
		margin-top: 36px;
	}
}

.big-header {
	width: 100%;
	// height: 80vh;
	background-color: $marknadsBlue;
	overflow: hidden;
	position: relative;
	padding: 86px 0 26px 0;

	@media screen and (max-width: 640px) {
		height: auto;
		padding: 86px 0 0 0;
	}

	&__inner {
		width: 100%;
		margin: 0 auto 48px auto;
		max-width: 1216px;
		// height: 100%;
		margin: 0 auto;
		padding: 100px 48px 70px 48px;
		display: flex;
		align-items: center;
		justify-content: center;

		&.no-video {
			justify-content: flex-start;
		}

		@media screen and (max-width: 640px) {
			flex-direction: column;
			padding: 0;
			height: auto;
		}
	}

	& .hero-background {
		position: absolute;
		top: 84px;
		bottom: 0;
		right: 0;
		left: 60%;
	}
	& .hero-icon {
		top: 0;
		right: 0;
		bottom: 0;
		// @include align-both(absolute);
	}
	& .text-container {
		width: 100%;
		max-width: 60%;
		@media screen and (max-width: 640px) {
			max-width: 100%;
		}
	}
	& .text-container:not(.no-video) {
		width: 50%;
		padding-right: 24px;
		@media screen and (max-width: 640px) {
			width: 100%;
			padding-bottom: 24px;
		}
	}
	& .text-container {
		@media screen and (max-width: 640px) {
			padding-left: 24px;
			padding-right: 24px;
		}
	}

	& .heading-xxl {
		width: 100%;
		margin-bottom: 60px;

		&.no-video {
			max-width: 960px;
		}

		@media screen and (max-width: 640px) {
			max-width: none;
			padding-top: 70px;
			margin-bottom: 24px;
		}
	}

	& .heading-md {
	}

	& .body-lg {
		margin-top: 28px;
	}

	&__image {
		width: 50%;
		img {
			display: block;
			max-width: 100%;
		}
	}

	&__video {
		width: 50%;
		position: relative;
		max-width: 640px;
		max-height: 392px;
		min-height: 392px;

		@media screen and (max-width: 640px) {
			width: 100%;
			min-height: 250px;
			max-width: none;
		}

		&-play {
			width: 88px;
			height: 88px;
			border-radius: 100%;
			background: #fff;
			z-index: 10;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			transition: opacity 0.2s ease-in-out;

			&:hover {
				opacity: 0.7;
			}
		}

		.video-wrapper {
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;

			video {
				object-fit: cover;
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;

				// @media screen and (max-width: 640px) {
				// 	object-fit: none;
				// 	position: relative;
				// 	height: auto;
				// }
			}
		}
	}

	&__scroll {
		width: 48px;
		height: 48px;
		margin: 0 auto;
		border-radius: 100%;
		border: 2px solid #fff;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		@media screen and (max-width: 640px) {
			display: none;
		}

		&:hover {
			.icon-down-open-big {
				transform: translateY(3px);
			}
		}

		.icon-down-open-big {
			color: #fff;
			font-size: 26px;
			transition: transform 0.2s ease-in-out;
		}
	}

	.big-hero-content {
		p {
			padding-bottom: 20px;
		}
		a {
			text-decoration: underline;
			color: white;
			margin-top: 10px;
		}
		a:hover {
			opacity: 80%;
		}
	}
}

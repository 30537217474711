.link-slider {
	width: 100%;
	margin: 0 auto;
	padding: 84px 24px 104px 24px;
	background: $lightBlue;

	@media screen and (max-width: 768px) {
		padding: 33px 24px;
	}

	& .heading-xl {
		width: 100%;
		max-width: 1216px;
		padding: 0 48px;
		margin: 0 auto 48px auto;
		@media screen and (max-width: 768px) {
			display: none;
		}
	}

	& .heading-xl {
		width: 100%;
		max-width: 1216px;
		padding: 0 48px;
		margin: 0 auto 48px auto;
		@media screen and (max-width: 768px) {
			display: none;
		}
	}

	// &__title {
	// 	width: 100%;
	// 	max-width: 1216px;
	// 	padding: 0 48px;
	// 	margin: 0 auto 48px auto;

	// }

	&__slider {
		width: 100%;
		max-width: 1216px;
		margin: 0 auto;
		position: relative;
		padding: 0 48px;

		@media screen and (max-width: 640px) {
			padding: 0 28px;
		}
	}

	.flickity-viewport {
		width: 100%;
		overflow: hidden;
	}

	.flickity-button {
		width: 48px;
		height: 48px;
		margin: 0 auto;
		border-radius: 100%;
		border: 2px solid #000;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		position: absolute;
		background: transparent;
		top: 50%;
		transform: translateY(-50%);
		transition: opacity 0.2s ease-in-out;

		@media screen and (max-width: 640px) {
			width: 28px;
			height: 28px;
		}

		&:disabled {
			opacity: 0.4;
			cursor: not-allowed;

			&:hover {
				opacity: 0.4;
			}
		}

		&:hover {
			opacity: 0.7;
		}

		&.previous {
			left: -20px;

			@media screen and (max-width: 640px) {
				left: -14px;
			}
		}

		&.next {
			right: -20px;

			@media screen and (max-width: 640px) {
				right: -14px;
			}
		}

		svg {
			width: 24px;
			height: 24px;
		}
	}

	&__slide {
		width: 100%;
		display: flex;
		margin-right: 16px;
		min-height: 360px;
		cursor: grabbing;

		@media screen and (max-width: 768px) {
			flex-direction: column;
			min-height: none;
			margin-right: 0;
			padding: 0 6px;
		}

		&-img {
			width: 50%;
			position: relative;

			@media screen and (max-width: 768px) {
				width: 100%;
			}

			img {
				max-width: 100%;
				position: absolute;
				object-fit: cover;
				width: 100%;
				height: 100%;

				@media screen and (max-width: 768px) {
					position: relative;
					object-fit: contain;
					width: auto;
					height: auto;
				}
			}
		}

		&-text {
			width: 50%;
			background: #fff;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			padding: 16px 5%;

			@media screen and (max-width: 768px) {
				width: 100%;
				background: transparent;
				padding: 16px 0;
			}

			& .heading-lg {
				margin-bottom: 16px;
			}
			& .heading-sm {
				margin-bottom: 16px;
			}

			.button {
				margin-top: 16px;
			}
		}
	}
}

.gray-text-block {
	width: 100%;

	background: $lighestGray;
	padding: 116px 0;

	.max-w-md {
		max-width: 752px;
		margin-right: auto;
	}
	@media screen and (max-width: 640px) {
		padding: 68px 0;
	}

	& .heading-xl {
		margin-bottom: 16px;
	}
}

.general-slider {
	width: 100%;
	margin: 0 auto;
	padding: 84px 24px 104px 24px;
	background: $lightBlue;

	@media screen and (max-width: 768px) {
		padding: 33px 24px;
	}

	&__slider {
		width: 100%;
		max-width: 1216px;
		margin: 0 auto;
		position: relative;
		padding: 0 48px;

		@media screen and (max-width: 640px) {
			padding: 0 28px;
		}
	}

	.flickity-viewport {
		width: 100%;
		overflow: hidden;
	}

	.flickity-button {
		width: 48px;
		height: 48px;
		margin: 0 auto;
		border-radius: 100%;
		border: 2px solid $marknadsBlue;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		position: absolute;
		background: transparent;
		top: 50%;
		transform: translateY(-50%);
		transition: opacity 0.2s ease-in-out;

		@media screen and (max-width: 640px) {
			width: 28px;
			height: 28px;
		}

		&:disabled {
			opacity: 0.4;
			cursor: not-allowed;

			&:hover {
				opacity: 0.4;
			}
		}

		&:hover {
			opacity: 0.7;
		}

		&.previous {
			left: -20px;

			@media screen and (max-width: 640px) {
				left: -14px;
			}
		}

		&.next {
			right: -20px;

			@media screen and (max-width: 640px) {
				right: -14px;
			}
		}

		svg {
			width: 24px;
			height: 24px;
		}
	}

	.flickity-button-icon {
		fill: $marknadsBlue;
	}

	&__slide {
		width: calc(100% / 3);
		cursor: grabbing;
		margin-right: 21px;

		@media screen and (max-width: 868px) {
			width: calc(100% / 2);
		}

		@media screen and (max-width: 640px) {
			width: calc(100% - 12px);
			margin-right: 12px;
		}

		&-image {
			width: 100%;
			position: relative;

			img {
				max-width: 100%;
				display: block;
			}
		}

		&-text {
			color: #000;
			font-size: $textS;
			margin-top: 12px;
			max-width: 80%;
		}
	}
}

.documents-block {
	width: 100%;
	background: #fff;
	padding-top: 117px;
	padding-bottom: 117px;

	@media screen and (max-width: 500px) {
		padding-top: 42px;
		padding-bottom: 42px;
	}

	&__inner {
		width: 100%;

		h2 {
			font-weight: 600;
			font-size: $textXL;
			margin: 0 auto 24px auto;

			@media screen and (max-width: 500px) {
				font-size: $textL;
			}
		}
	}

	&__columns {
		display: flex;
		flex-direction: column;
		margin-top: 80px;

		@media screen and (max-width: 500px) {
			margin-top: 50px;
		}
	}

	&__column {
		width: 100%;
		margin: 22px 0;
		border-bottom: 1px solid #272a3a;
		padding-bottom: 16px;

		a {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
		}

		.heading-md {
			width: 100%;
			display: flex;
			justify-content: space-between;
			// font-weight: 600;
			// font-size: $textM;
			margin-bottom: 16px;
			color: $marknadsBlue;
			cursor: pointer;
			transition: opacity 0.2s ease-in-out;

			&:hover {
				opacity: 0.6;
			}
		}

		.body {
			// font-size: $textS;
			// line-height: 1.6rem;
		}
	}
}

@import 'normalize';
@import 'entypo';
@import 'breaker';
@import 'mixins';
@import 'variables.scss';
@import 'typography.scss';

@import 'nav.scss';
@import 'weather-icons.scss';

@import 'components/site-navigation';
@import 'components/big-header';
@import 'components/small-columns';
@import 'components/track-columns';
@import 'components/text-block';
@import 'components/site-footer';
@import 'components/link-slider';
@import 'components/cta-columns';
@import 'components/text-button-block';
@import 'components/proving-grounds-block';
@import 'components/big-image-text-grid';
@import 'components/big-text-image-column';
@import 'components/image-text-block';
@import 'components/text-columns-block';
@import 'components/gray-text-block';
@import 'components/white-text-block';
@import 'components/big-text-column-rows';
@import 'components/general-slider';
@import 'components/text-image-block';
@import 'components/documents-block';
@import 'components/tracks-block';
@import 'components/image-text-popup';
@import 'components/timeline';
@import 'components/team-block';
@import 'components/three-column-block';

body {
	color: $blackAsh;
	font-family: $familjen;
}

.loading-spinner {
	background-repeat: no-repeat;
	background-position: center;
	background-image: url('../img/loading.svg');
}

.row {
	width: 100%;
	max-width: 1168px;
	margin-left: auto;
	margin-right: auto;
	padding-left: 24px;
	padding-right: 24px;
}

@media screen and (min-width: 768px) {
	.row.row-reverse {
		display: flex;
		flex-direction: row-reverse;
	}
}
.button {
	font-size: $textS;
	background: $primary2;
	color: #fff;
	padding: 16px;
	display: inline-block;
	min-width: 224px;
	text-align: center;
	text-decoration: none;
	transition: opacity 0.2s ease-in-out;

	&:hover {
		opacity: 0.7;
	}

	&--black {
		background: $blackAsh;
	}

	&--white {
		background: #fff;
		color: #000;
	}
	&--blue {
		color: #fff;
		background: $marknadsBlue;
	}
}

#breadcrumbs {
	position: absolute;
	margin: 16px 0 0 24px;
	color: #fff;

	@media screen and (max-width: 640px) {
		display: none;
	}

	a {
		color: #fff;
		transition: opacity 0.2s ease-in-out;

		&:hover {
			opacity: 0.6;
		}
	}

	.breadcrumb_last {
		text-decoration: underline;
	}

	.breadcrumbs-divider {
		width: 8px;
		height: 14px;
		margin: 0 16px 0 4px;
		top: 2px;
		position: relative;
		background-repeat: no-repeat;
		background-size: contain;
		display: inline-block;
		background-image: url('../img/white-right-arrow.svg');
	}
}

.wysiwyg {
	p {
		margin: 0 0 20px 0;
	}

	ul,
	ol {
		margin: 20px 0;
		padding: 0 40px;
	}

	a {
		color: $marknadsBlue;
		transition: opacity 0.2s ease-in-out;

		&:hover {
			opacity: 0.6;
		}
	}
}

.site-header {
	height: 85vh;

	@include below(1024px) {
		.header-video {
			display: none;

			video {
				display: none;
			}
		}
	}

	@include below(500px) {
		height: auto;
	}

	&.full-height {
		height: 100%;
	}

	&__small-video {
		width: 100%;
		max-width: 960px;
		display: block;
		margin: 50px auto;
	}

	&__image {
		margin: 0 auto;
		display: block;
		max-width: 100%;
	}
}

pre {
	text-align: left !important;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
	display: block;
}

*:focus {
	outline: none;
	-webkit-appearance: none;
}

audio,
canvas,
video {
	display: inline-block;
}

.line-legend li span {
	display: block;
	width: 14px;
	height: 14px;
	border-radius: 7px;
	float: left;
	margin-top: 4px;
	margin-right: 8px;
}

.line-legend {
	list-style: none;
	margin: 0;
	padding: 0;
	font-size: 14px;
	margin-top: 20px;
}

.line-legend li {
	margin-bottom: 4px;
}

.doughnut-legend li:first-letter {
	text-transform: capitalize;
}

audio:not([controls]) {
	display: none;
	height: 0;
}

[hidden] {
	display: none;
}
#facebook {
	position: fixed;
	z-index: 99;
	right: 0;
	top: 50%;
	margin-top: -20px;
}
#facebook a {
	padding: 15px;
	display: block;
	color: white;
	background: #3b5998;
}
html {
	font-family: sans-serif;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
}

body {
	margin: 0;
	position: relative;
}

a:focus {
	outline: thin dotted;
}

a:active,
a:hover {
	outline: 0;
}

h1 {
	font-size: 2em;
	margin: 0.67em 0;
}

abbr[title] {
	border-bottom: 1px dotted;
}

b,
strong {
	font-weight: bold;
}

dfn {
	font-style: italic;
}

hr {
	-moz-box-sizing: content-box;
	box-sizing: content-box;
	height: 0;
}

mark {
	background: #ff0;
	color: #000;
}

code,
kbd,
pre,
samp {
	font-family: monospace, serif;
	font-size: 1em;
}

pre {
	white-space: pre-wrap;
}

q {
	quotes: '\201C''\201D''\2018''\2019';
}

small {
	font-size: 80%;
}

sub,
sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}

sup {
	top: -0.5em;
}

sub {
	bottom: -0.25em;
}

img {
	border: 0;
}

svg:not(:root) {
	overflow: hidden;
}

figure {
	margin: 0;
}

fieldset {
	border: 1px solid #c0c0c0;
	margin: 0 2px;
	padding: 0.35em 0.625em 0.75em;
}

legend {
	border: 0;
	padding: 0;
}

button,
input,
select,
textarea {
	font-family: inherit;
	font-size: 100%;
	margin: 0;
}

button,
input {
	line-height: normal;
}

button,
select {
	text-transform: none;
}

button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
	-webkit-appearance: button;
	cursor: pointer;
}

button[disabled],
html input[disabled] {
	cursor: default;
}

input[type='checkbox'],
input[type='radio'] {
	box-sizing: border-box;
	padding: 0;
}

.showifie {
	display: none;
}

input[type='search'] {
	-webkit-appearance: textfield;
	-moz-box-sizing: content-box;
	-webkit-box-sizing: content-box;
	box-sizing: content-box;
}

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
	-webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
	border: 0;
	padding: 0;
}

textarea {
	overflow: auto;
	vertical-align: top;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

.clearfix:after {
	content: '.';
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}

.clearfix {
	display: inline-block;
}

html[xmlns] .clearfix {
	display: block;
}

* html .clearfix {
	height: 1%;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	font-weight: normal;
}

ul {
	margin: 0;
	padding: 0;
}

/*COLOURS*/
html,
body {
	height: 100%;
}

// body {
// 	font-family: 'Aller', sans-serif;
// }
// h1 {
// 	font-family: 'bebas-neue', sans-serif;
// 	letter-spacing: 2px;
// 	font-weight: 400;
// }
// h2 {
// 	font-family: 'Aller', sans-serif;
// 	font-weight: 400;
// }
textarea,
input {
	outline: none;
}

* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

a {
	text-decoration: none;
}

a.wrap {
	// display: inline-block;
	display: block;
	width: 100%;
	// margin-bottom: -5px;
}

.site-wrap {
	transition: 0.3s all;
}

.site-wrap.toggled {
	transform: translateX(-300px);
}

.holder {
	width: 100%;
	position: relative;
	display: block;
}

.holder.main {
	position: relative;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	z-index: 2;
}

.holder.pg {
	padding-top: 13%;
	max-width: 1200px;
	margin: 0 auto;
	z-index: 2;
}

h1,
h2 {
	font-weight: bold;
}
/*HEADER*/
.header-video {
	height: auto;
	width: 100%;
}

.SearchOverride {
	background-color: black;
}

.burger-wrap {
	display: none;
}

.logo {
	position: absolute;
	top: 25px;
	left: 25px;
	max-width: 276px;
	z-index: 5;
	-webkit-transition: 0.3s all;
	-moz-transition: 0.3s all;
	transition: 0.3s all;
	-webkit-transition-delay: 0.2s;
	-moz-transition-delay: 0.2s;
	transition-delay: 0.2s;
}

.logo img {
	width: 100%;
}

header {
	width: 100%;
	height: 100%;
	background-size: cover !important;
	overflow: hidden;
	position: relative;
}

header video#bgvid {
	position: absolute;
	right: 0;
	bottom: 0;
	min-width: 100%;
	min-height: 100%;
	height: auto;
	width: auto;
	z-index: 0;
}

header .copy.main {
	text-align: left;

	&.with-weather {
		text-align: left;
	}
}

header .copy.pg {
	text-align: left;
}

header .copy h1 {
	color: #fff;
	margin-bottom: 30px;
}

header .copy h3 {
	color: #fff;
	margin-bottom: 30px;
	max-width: 1000px;
	margin-left: auto;
	margin-right: auto;
}

header .copy {
	max-width: 960px;
	margin-left: auto;
	margin-right: auto;
}

header .copy p {
	color: #fff;
}

header .copy a {
	color: #fff;
	margin-right: 15px;
	-webkit-transition: 0.2s all ease-in-out;
	-moz-transition: 0.2s all ease-in-out;
	-o-transition: 0.2s all ease-in-out;
	transition: 0.2s all ease-in-out;
}

header .copy a:hover {
	color: $primary2;
}

header .copy i {
	margin-right: 10px;
}

.js-scroll-up {
	padding-top: 0 !important;
	bottom: 0 !important;
	top: 50%;
	transform: translateY(-50%);
}

header .copy.js-scroll-down,
.js-scroll-up {
	position: absolute;
	bottom: 30px;
	height: 60px;
	width: 60px;
	border: 3px solid #fff;
	border-radius: 300px;
	cursor: pointer;
	left: 50%;
	padding-top: 5px;
	margin-left: -30px;
	-webkit-transition: 0.2s all ease-in-out;
	-moz-transition: 0.2s all ease-in-out;
	-o-transition: 0.2s all ease-in-out;
	transition: 0.2s all ease-in-out;
	z-index: 2;
}

.js-scroll-up i {
	padding-left: 0 !important;
	position: relative;
	bottom: 0px;
	left: -1px;
	-webkit-transition: 0.2s all ease-in-out;
	-moz-transition: 0.2s all ease-in-out;
	-o-transition: 0.2s all ease-in-out;
	transition: 0.2s all ease-in-out;
}

header .copy.js-scroll-down i,
.js-scroll-up i {
	font-size: 36px;
	color: #fff !important;
	padding-top: 5px;
	padding-left: 2px;
	margin-right: 0;
	-webkit-transition: 0.2s all ease-in-out;
	-moz-transition: 0.2s all ease-in-out;
	-o-transition: 0.2s all ease-in-out;
	transition: 0.2s all ease-in-out;
}

header .copy.js-scroll-down:hover {
	padding-top: 10px;
	border: 3px solid $primary2;
}

.js-scroll-up:hover {
	border: 3px solid $primary2;
}

.js-scroll-up:hover i {
	bottom: 5px;
}

header .buttons {
	margin: 50px 0;
	text-align: center;
}

header .buttons span {
	display: inline-block;
	width: 300px;
	color: #fff;
	font-size: 21px;
	padding: 20px 0;
	cursor: pointer;
	margin: 10px;
	background: rgba(255, 255, 255, 0.3);
	-webkit-transition: 0.4s all ease-in-out;
	-moz-transition: 0.4s all ease-in-out;
	-o-transition: 0.4s all ease-in-out;
	transition: 0.4s all ease-in-out;
}

header .buttons span a {
	color: #fff;
}

header .buttons span:hover {
	background: rgba(214, 131, 0, 0.8);
}

::-webkit-input-placeholder {
	/* WebKit browsers */
	color: #fff;
}

:-moz-placeholder {
	/* Mozilla Firefox 4 to 18 */
	color: #fff;
	opacity: 1;
}

::-moz-placeholder {
	/* Mozilla Firefox 19+ */
	color: #fff;
	opacity: 1;
}

:-ms-input-placeholder {
	/* Internet Explorer 10+ */
	color: #fff;
}

.search-head {
	height: auto;
	padding-bottom: 120px;
}

.mobile-search {
	display: none;
}

.js-toggle-search {
	position: absolute;
	right: 0;
	padding: 38px;
	padding-bottom: 37px;
	top: 0;
	z-index: 5;
	cursor: pointer;
	border-left: 1px solid #09446b;
}

.js-toggle-search:hover i {
	color: rgba(255, 255, 255, 1);
}

.search-overlay {
	height: 0px;
	z-index: 4;
	position: fixed;
	top: 95px;
	right: 0;
	left: 0;
	background: #093f62;
	border-top: 1px solid #09446b;
	transition: 0.3s all;
	-webkit-transition: 0.3s all;
	-moz-transition: 0.3s all;
}

.search-overlay input {
	height: 0%;
	display: none;
	transition: 0.3s all;
	-webkit-transition: 0.3s all;
	-moz-transition: 0.3s all;
}

.search-overlay.toggled {
	height: 55px;
}

.search-overlay.toggled input {
	background: transparent;
	height: 100%;
	display: block;
	border: none;
	padding-left: 30px;
	height: 50px;
	width: 100%;
	color: white;
	font-size: 22px;
}

.search-overlay span,
.mobile-search span {
	display: none !important;
}

.ui-autocomplete {
	display: none !important;
}

article {
	background: #fff;
}

.flex {
	display: flex;
}

article.col-2 {
	width: 100%;
	// min-height: 560px;
}

article.col-2 div {
	width: 50%;
	display: inline-block;
	text-align: left;
	float: left;
	max-height: 600px;
	position: relative;
	overflow: hidden;
}

article.col-2 div.copy {
	padding: 5%;
	padding-right: 20%;
	height: 100%;
}

article.col-2 div.image {
	background-size: cover;
	background-position: center center;
	transition: 0.2s;

	&:hover {
		opacity: 0.8;
	}
}

article.col-2 div.image.image-right {
	order: 2;
}

article.col-2 div img {
	width: 100%;
	-webkit-transition: 0.3s all ease-in-out;
	-moz-transition: 0.3s all ease-in-out;
	-o-transition: 0.3s all ease-in-out;
	transition: 0.3s all ease-in-out;
	opacity: 1;
	display: block;
	height: 100%;
}

article.col-2 div img:hover {
	opacity: 0.8;
}

article.col-2 div iframe {
	width: 100%;
}

article.col-2 div:hover span {
	margin-left: -11px;
}

article.col-2 div:hover span i {
	opacity: 1;
}

article h2 {
	font-size: 36px;
	line-height: 46px;
	margin-bottom: 20px;
	color: #272a3b;
}

article h3 {
	font-size: 21px;
	line-height: 30px;
	margin-bottom: 20px;
	color: #272a3b;
}

article p {
	font-size: 16px;
	line-height: 24px;
	margin-bottom: 15px;
	color: #272a3b;
}

article span {
	font-size: 21px;
	line-height: 31px;
	color: $primary2;
	margin-left: -31px;
	-webkit-transition: 0.3s all ease-in-out;
	-moz-transition: 0.3s all ease-in-out;
	-o-transition: 0.3s all ease-in-out;
	transition: 0.3s all ease-in-out;
}

article span i {
	font-size: 21px;
	opacity: 0;
	-webkit-transition: 0.3s opacity ease-in-out;
	-moz-transition: 0.3s opacity ease-in-out;
	-o-transition: 0.3s opacity ease-in-out;
	transition: 0.3s opacity ease-in-out;
}
/*PROVING GROUNDS*/
header.pg {
	height: auto;
	padding-bottom: 5%;
	overflow: hidden;
	.overlay-blue {
		height: 100%;
	}
}

.overview {
	display: none;
}

.copy .left {
	width: 50%;
	float: left;
	position: relative;
}

.copy .right {
	width: 50%;
	display: inline-block;
	padding: 1%;
	padding-left: 12%;
	position: relative;
}

.copy .right i#weather {
	font-size: 100px;
	color: #fff;
	float: left;
	margin-right: 15px;
	position: relative;
	top: -15px;
}

.copy .right i.icon-right-open {
	color: $primary2;
}

.copy .right a {
	color: #fff;
	font-size: 21px;
	position: absolute;
	left: 22%;
	margin-top: 0px;
	text-decoration: none;
	-webkit-transition: 0.2s all ease-in-out;
	-moz-transition: 0.2s all ease-in-out;
	-o-transition: 0.2s all ease-in-out;
	transition: 0.2s all ease-in-out;
}

.copy .right p {
	color: #fff;
	font-size: 18px;
	position: absolute;
	left: 22%;
	margin-top: 59px;
	line-height: 24px;
}

.map {
	width: 100%;
	position: relative;
	height: 500px;
	background-size: cover !important;
	margin-top: 95px;
}

.map div.pin {
	background: url('../img/pin.png') no-repeat;
	height: 30px;
	width: 20px;
	display: inline-block;
	position: absolute;
	cursor: pointer;
}

/*Vitberget*/
.map div.pin.no-13-vitberget {
	left: 434px;
	top: 281px;
}

.map div.pin.no-14-vitberget {
	left: 397px;
	top: 262px;
}

.map div.pin.no-15-vitberget {
	left: 358px;
	top: 229px;
}

.map div.pin.no-16-vitberget {
	left: 320px;
	top: 265px;
}

.map div.pin.no-19-vitberget {
	left: 489px;
	top: 328px;
}

.map div.pin.no-1-vitberget {
	left: 555px;
	top: 128px;
}

.map div.pin.no-7-vitberget {
	left: 664px;
	top: 142px;
}

.map div.pin.no-3-vitberget {
	left: 823px;
	top: 201px;
}

.map div.pin.no-4-vitberget {
	left: 937px;
	top: 172px;
}

.map div.pin.no-2-vitberget {
	left: 855px;
	top: 258px;
}

.map div.pin.no-5-vitberget {
	left: 547px;
	top: 192px;
}

.map div.pin.no-11-vitberget {
	left: 204px;
	top: 216px;
}

.map div.pin.no-20-vitberget {
	left: 636px;
	top: 287px;
}

.map div.pin.no-21-vitberget {
	left: 447px;
	top: 239px;
}

.map div.pin.no-22-vitberget {
	left: 416px;
	top: 209px;
}

.map div.pin.no-23-vitberget {
	left: 229px;
	top: 189px;
}

.map div.pin.no-9-vitberget {
	left: 230px;
	top: 226px;
}

.map div.pin.no-8-vitberget {
	left: 225px;
	top: 272px;
}

.map div.pin.no-7-vitberget {
	left: 127px;
	top: 309px;
}

.map div.pin.no-11-vitberget {
	left: 200px;
	top: 210px;
}

.map div.pin.no-12-vitberget {
	left: 40px;
	top: 336px;
}

.map div.pin.no-10-vitberget {
	left: 154px;
	top: 245px;
}

.map div.pin.no-6-vitberget {
	left: 70px;
	top: 240px;
}

.map div.pin.no-17-vitberget {
	left: 569px;
	top: 337px;
}

.map div.pin.no-18-vitberget {
	left: 445px;
	top: 337px;
}

.map div.pin.no-19-vitberget {
	left: 474px;
	top: 306px;
}

/*Lillkorsträsk*/
.map div.pin.no-1-lillkorstrask {
	left: 241px;
	top: 224px;
}

.map div.pin.no-2-lillkorstrask {
	left: 237px;
	top: 149px;
}

.map div.pin.no-3-lillkorstrask {
	left: 666px;
	top: 285px;
}

.map div.pin.no-4-lillkorstrask {
	left: 443px;
	top: 73px;
}

/*Plantskolan*/
.map div.pin.no-1-plantskolan {
	left: 715px;
	top: 22px;
}

.map div.pin.no-2-plantskolan {
	left: 569px;
	top: 56px;
}

.map div.pin.no-3-plantskolan {
	left: 710px;
	top: 93px;
}

.map div.pin.no-4-plantskolan {
	left: 696px;
	top: 179px;
}

.map div.pin.no-5-plantskolan {
	left: 485px;
	top: 156px;
}

.map div.pin.no-6-plantskolan {
	left: 352px;
	top: 381px;
}

.map div.pin.no-7-plantskolan {
	left: 638px;
	top: 328px;
}

/*Natberg*/
.map div.pin.no-1-nattberg {
	left: 625px;
	top: 201px;
}

.map div.pin span.number {
	border-radius: 100px;
	background: #000;
	border: 1px solid #fff;
	color: #fff;
	width: 20px;
	height: 20px;
	position: relative;
	top: -10px;
	left: 10px;
	font-size: 12px;
	display: inline-block;
	text-align: center;
	padding-top: 1px;
}

.map div.pin span.bubble {
	width: 230px;
	font-size: 17px;
	position: absolute;
	height: auto;
	padding: 15px;
	color: #fff;
	z-index: 2;
	background: $primary2;
	top: -95px;
	left: -95px;
	margin-bottom: 30px;
	display: none;
	-webkit-box-shadow: 1px 10px 5px 0px rgba(50, 50, 50, 0.75);
	-moz-box-shadow: 1px 10px 5px 0px rgba(50, 50, 50, 0.75);
	box-shadow: 1px 10px 5px 0px rgba(50, 50, 50, 0.75);
	-webkit-transition: 0.2s all ease-in-out;
	-moz-transition: 0.2s all ease-in-out;
	-o-transition: 0.2s all ease-in-out;
	transition: 0.2s all ease-in-out;
}

.map div.pin span.bubble i {
	color: #000;
	font-size: 18px;
	margin-left: -5px;
}

.map div.pin span.bubble:after {
	content: '';
	position: absolute;
	bottom: -10px;
	left: 95px;
	width: 0;
	height: 0;
	display: block;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-top: 10px solid $primary2;
}

.map div.pin span.bubble.toggled {
	display: block;
}

.map .pg-content {
	height: 100%;
	width: 100%;
	position: absolute;
	z-index: 2;
	display: none;
}

.map .pg-content .icon-cancel {
	color: #000;
	font-size: 28px;
	cursor: pointer;
	position: absolute;
	top: 15px;
	right: 15px;
	-webkit-transition: 0.3s all ease-in-out;
	-moz-transition: 0.3s all ease-in-out;
	-o-transition: 0.3s all ease-in-out;
	transition: 0.3s all ease-in-out;
}

.map .pg-content .icon-cancel:hover {
	-webkit-transform: rotate(90deg);
	/* Safari and Chrome */
	-ms-transform: rotate(90deg);
	/* IE 9 */
	transform: rotate(90deg);
}

.map .pg-content .img {
	width: 50%;
	height: 100%;
	float: left;
	position: relative;
}

.map .pg-content .img .gallery {
	position: absolute;
	bottom: 0;
	text-align: center;
	padding: 10px;
	margin: 0 104px;
}

.map .pg-content .img .gallery img {
	width: 80px;
	height: 80px;
	margin: 0 5px;
	cursor: pointer;
}

.map .pg-content .img .gallery img.active {
	border: 1px solid $primary2;
}

.map .pg-content .img img {
	width: 100%;
	height: 100%;
}

.map .pg-content .copy {
	width: 50%;
	height: 100%;
	float: right;
	background: #fff;
	padding: 5%;
}

.map .pg-content .copy h2 {
	font-size: 40px;
	color: #000;
	margin-bottom: 30px;
}

.map .pg-content .copy p {
	color: #000;
	line-height: 31px;
	font-size: 21px;
	margin-bottom: 30px;
}

.map .pg-content .copy a {
	color: $primary2;
	font-size: 21px;
	text-decoration: none;
	-webkit-transition: 0.2s all ease-in-out;
	-moz-transition: 0.2s all ease-in-out;
	-o-transition: 0.2s all ease-in-out;
	transition: 0.2s all ease-in-out;
}

.map .pg-content .copy a:hover {
	text-decoration: underline;
}

.map .pg-content .controls {
	position: absolute;
	right: 30px;
	bottom: 30px;
}

.map .pg-content .controls span {
	display: inline-block;
	text-align: center;
	height: 25px;
	width: 25px;
	padding-top: 5px;
	font-size: 12px;
}

.map .pg-content .controls span.current {
	background: $primary2;
	color: #fff;
	border-radius: 200px;
}

.map .pg-content .controls span.total {
	background: #272a3b;
	color: #fff;
	border-radius: 200px;
}

.map .pg-content .controls span.divider {
	color: $primary2;
	font-size: 36px;
	position: relative;
	top: 10px;
	padding-top: 0;
}

.map .pg-content .controls i {
	cursor: pointer;
	font-size: 22px;
	position: relative;
	top: 4px;
}
/*WEATHER*/
section.weather {
	max-width: 1400px;
	margin: 0 auto;
	background: #fff;
	padding: 100px;
}

section.weather .weather-wrap {
	position: relative;
	width: 100%;
}

section.weather .weather-wrap .weather-item {
	width: 20%;
	margin: 60px 80px 60px 0px;
	float: left;
}

section.weather .weather-wrap .weather-item h3 {
	color: #272a3b;
	margin-bottom: 15px;
}

section.weather .weather-wrap .weather-item span {
	color: $primary2;
	text-transform: none;
}

section.weather .weather-wrap .weather-item:nth-child(4n) {
	margin-right: 0;
}

section.weather-plotting {
	width: 100%;
	max-width: 1440px;
	margin-left: auto;
	margin-right: auto;
	background: $lightBlue;
	padding: 100px;
}

section.weather-plotting .holder {
	max-width: 1400px;
	margin: 0 auto;
}

section.weather-plotting .holder .head {
	padding-bottom: 100px;
	width: 100%;
	border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

section.weather-plotting .holder .head h2 {
	float: left;

	max-width: 395px;
	// color: #fff;
}

section.weather-plotting .holder .head p {
	float: right;
	max-width: 600px;
	// color: #fff;
}

section.weather-plotting .holder .plotter {
	padding: 24px 20px;
	width: 100%;
}

section.weather-plotting .holder .plotter .plot-item {
	width: 20%;
	float: left;
	margin-right: 80px;
}

section.weather-plotting .holder .plotter .plot-item:last-child {
	margin-right: 0;
}

section.weather-plotting .holder .plotter .plot-item p {
	font-size: $textL;
	font-weight: 600;
	// color: #fff;
	margin-bottom: 15px;
}

section.weather-plotting .holder .plotter .plot-item span {
	color: $primary2;
}

section.weather-plotting .holder .plotter .plot-item span.left {
	margin-left: -17px;
}

section.weather-plotting .holder .plotter .plot-item span.button {
	margin-top: 24px;
	color: $white;
	// text-align: center;
	// background: #fff;
	// padding: 20px 0px;
	// width: 100%;
	// display: block;
	// color: rgba(255, 255, 255, 0.8);
	// color: #000;
	// cursor: pointer;
	// -webkit-transition: 0.2s all ease-in-out;
	// -moz-transition: 0.2s all ease-in-out;
	// -o-transition: 0.2s all ease-in-out;
	// transition: 0.2s all ease-in-out;
}

// section.weather-plotting .holder .plotter .plot-item span.button:hover {
// 	background: $primary2;
// }

section.weather-plotting .holder .plotter select {
	background: transparent;
	color: $primary2;
	border: none;
	padding: 0 15px;
	margin-right: 5px;
	appearance: none;
	-moz-appearance: none;
	/* Firefox */
	-webkit-appearance: none;
	/* Safari and Chrome */
	background: url('../img/select-arrow.svg');
	background-position: right;
	background-repeat: no-repeat;
	cursor: pointer;
}

.plotter select option {
	text-align: center;
	background: #575675;
}

.plot-area {
	height: auto;
	max-height: 0px;
	overflow: hidden;
	-webkit-transition: 0.2s all ease-in-out;
	-moz-transition: 0.2s all ease-in-out;
	-o-transition: 0.2s all ease-in-out;
	transition: 0.2s all ease-in-out;
}

.plot-area.toggled {
	max-height: 765px;
}

.button.hide-graph {
	text-align: center;
	background: #84868d;
	padding: 20px 40px;
	display: block;
	max-width: 300px;
	margin: 0 auto;
	color: rgba(255, 255, 255, 0.8);
	color: #fff;
	font-size: 21px;
	cursor: pointer;
	-webkit-transition: 0.2s all ease-in-out;
	-moz-transition: 0.2s all ease-in-out;
	-o-transition: 0.2s all ease-in-out;
	transition: 0.2s all ease-in-out;
}

.button.hide-graph:hover {
	background: $primary2;
}
/*ABOUT*/
section.about {
	background: #fff;
	width: 100%;
	margin: 100px 0;
}

section.about article {
	max-width: 720px;
	margin: 0 auto;
}

section.about article h2 {
	font-size: 26px;
	line-height: 36px;
	margin-bottom: 30px;
	color: #272a3b;
}

section.about article h1 {
	font-size: 32px;
	line-height: 42px;
	margin-bottom: 30px;
	color: #272a3b;
}

section.about article h3 {
	font-size: 30px;
	line-height: 40px;
	margin-bottom: 30px;
	color: #272a3b;
}

section.about article p {
	margin-bottom: 30px;
	color: #272a3b;
}
/*CONTACT*/
section.contact {
	width: 100%;
}

section.contact .holder {
	max-width: 1400px;
	padding: 80px;
	margin: 0 auto;
	font-size: 0;
}

section.contact .holder .contact-item {
	width: calc(25% - 30px);
	margin-top: 40px;
	margin-right: 40px;
	display: inline-block;
	vertical-align: top;
}

section.contact .holder .contact-item:nth-child(4n) {
	margin-right: 0px;
}

section.contact .holder .contact-item img {
	width: 100%;
}

section.contact .holder .contact-item h2 {
	font-size: 32px;
	margin-top: 20px;
}

section.contact .holder .contact-item h3 {
	font-size: 21px;
	margin: 10px 0;
}

section.contact .holder .contact-item p {
	font-size: 16px;
	line-height: 31px;
}

section.contact .holder .contact-item a {
	color: $primary2;
	margin-left: 5px;
	font-size: 16px;
}
/*ABOUT*/
section.search {
	background: #fff;
	width: 100%;
	padding: 100px 0;
}

section.search .holder {
	max-width: 1000px;
	margin: 0 auto;
}

section.search .holder h2 {
	font-size: 60px;
	line-height: 75px;
	margin-bottom: 50px;
	color: #272a3b;
}

section.search .holder h3 {
	font-size: 30px;
	line-height: 40px;
	margin-bottom: 5px;
	color: #272a3b;
}

section.search .holder h4 {
	font-size: 30px;
	line-height: 31px;
	margin-bottom: 10px;
	color: #272a3b;
}

section.search .holder p {
	font-size: 21px;
	line-height: 31px;
	margin-bottom: 10px;
	color: #272a3b;
}

section.search span {
	color: $primary2;
	font-size: 21px;
	padding-bottom: 10px;
	display: inline-block;
}

section.search .holder hr {
	font-size: 30px;
	line-height: 40px;
	margin-top: 30px;
	color: #272a3b;
}

section.search .highlight {
	font-size: 21px;
	line-height: 31px;
	margin-bottom: 10px;
	color: $primary2;
}

/*FOOTER*/
footer {
	// height: 250px;
	// width: 100%;
	// background: #0063a3;
	// position: relative;
	// bottom: -10px;
	// margin-top: -13px;
	// border-top: 1px solid rgba(255, 255, 255, 0.5);
}

footer .holder {
	position: relative;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

footer .holder .copy {
	text-align: center;
	cursor: pointer;
}

footer .holder .copy p {
	color: #fff;
	font-size: 21px;
	line-height: 31px;
}

footer .holder .copy i {
	font-size: 40px;
	color: $primary2;
}

.NOsearchfooter {
	height: 80px;
	width: 100%;
	background: #575675;
	position: absolute;
	bottom: 0px;
}
/*Mid to high res monitors*/
@media all and (max-width: 1900px) {
	article.col-2 div.copy {
		padding-right: 5%;
	}
}

/*Tablet*/
@media all and (max-width: 1280px) {
	header .copy {
		// padding: 0 8%;
	}

	header .copy.js-scroll-down {
		padding: 5px 0px 0px 0px;
	}

	.flex {
		display: block;
	}

	article.col-2 {
		position: relative;
		background: rgba(50, 53, 65, 1);
		width: 100%;
	}

	article.col-2 div {
		min-width: 100%;
	}

	.copy.main {
		// padding: 0 ;
	}

	article.col-2 div.copy {
		padding: 5%;
		color: #fff;
		position: relative;
		z-index: 1;
		background: $marknadsBlue;
	}

	article.col-2 div.image.image-right {
		order: initial;
	}

	section.search {
		padding: 50px;
	}

	article.col-2 div.image {
		height: 578px;
	}

	article h2 {
		color: #fff;
		margin-bottom: 10px;
	}

	article h3 {
		color: #fff;
		margin-bottom: 10px;
	}

	article p {
		color: #fff;
		margin-bottom: 10px;
	}

	.logo {
		top: 15px;
		left: 25px;
	}

	.logo img {
		width: 70%;
	}

	.burger-wrap {
		position: absolute;
		right: 5px;
		top: 5px;
		cursor: pointer;
		display: block;
		z-index: 2;
		-webkit-transition: 0.3s all ease-in-out;
		-moz-transition: 0.3s all ease-in-out;
		-o-transition: 0.3s all ease-in-out;
		transition: 0.3s all ease-in-out;
	}

	.burger-wrap:hover i,
	.burger-wrap:hover h2 {
		color: rgba(255, 255, 255, 1);
	}

	.burger-wrap h2 {
		float: left;
		position: relative;
		top: 10px;
		right: 60px;
		font-weight: normal;
		font-size: 16px;
		text-transform: capitalize;
		color: rgba(255, 255, 255, 1);
		cursor: pointer;
		-webkit-transition: 0.3s all ease-in-out;
		-moz-transition: 0.3s all ease-in-out;
		-o-transition: 0.3s all ease-in-out;
		transition: 0.3s all ease-in-out;
	}

	.burger-wrap.toggled h2 {
		right: 225px;
	}

	.mobile-search {
		display: block;
		position: relative;
		padding: 40px 0 0 30px;
		border-top: 1px solid rgba(255, 255, 255, 0.1);
	}

	.mobile-search input {
		width: 240px;
		height: 40px;
		border: none;
		padding-left: 10px;
	}

	.mobile-search i.icon-search,
	.mobile-search i:hover {
		color: #272a3b;
		position: relative;
		border-bottom: none !important;
		right: 35px;
	}

	/*weather*/
	section.weather h2 {
		font-size: 48px;
	}

	section.weather-plotting .holder .head h2 {
		font-size: 48px;
		line-height: 58px;
		max-width: 300px;
	}

	section.weather-plotting .holder .head p {
		max-width: 480x;
	}

	section.weather-plotting .holder .plotter {
		padding: 50px;
	}

	section.weather-plotting .holder .plotter .plot-item {
		width: 39%;
		margin-bottom: 50px;
	}

	section.weather-plotting .holder .plotter .plot-item:last-child {
		margin-bottom: 0px;
		width: 100%;
	}

	section.weather {
		padding: 24px 20px;
		border-top: 1px solid rgba(0, 0, 0, 0.2);
	}

	section.weather .weather-wrap .weather-item {
		width: 41%;
		margin: 30px;
		margin-left: 0;
	}

	section.weather-plotting .holder .head {
		// padding-left: 50px;
		// padding-right: 50px;
		padding-bottom: 50px;
	}

	.button.hide-graph {
		width: 90%;
		max-width: none;
	}
	/*ABOUT*/
	section.about article {
		padding: 0 50px;
	}

	footer {
		height: 180px;
		// padding: 40px 0;
	}
}
/*Tablet(portrait)*/
@media all and (max-width: 780px) {
	article.col-2 div.image {
		height: 438px;
	}

	header .copy h1 {
		font-size: 40px;
		line-height: 55px;
	}

	header .copy h3 {
		font-size: 26px;
		line-height: 30px;
	}

	header .copy p {
		font-size: 18px;
		line-height: 28px;
	}

	header .copy i {
		width: 100%;
	}

	.map {
		display: none;
	}

	section.weather-plotting .holder .plotter .plot-item {
		width: 100%;
	}

	section.weather-plotting .holder .head h2 {
		width: 100%;
		max-width: none;
		font-size: 36px;
	}

	section.weather-plotting .holder .head p {
		width: 100%;
		max-width: none;
	}

	section.contact .holder .contact-item {
		width: 100%;
		margin: 15px 0 !important;
	}

	.overview {
		display: block;
		width: 100%;
		padding: 5%;
	}

	.overview .copy {
		padding: 5%;
		width: 100%;
		background: #575675;
	}

	.overview .copy h2,
	.overview .copy h3,
	.overview .copy p {
		color: #ffffff;
	}
}
/*Mobile*/
@media all and (max-width: 500px) {
	.holder.pg {
		padding-top: 20%;
	}

	.logo {
		max-width: 130px;
	}

	.logo img {
		width: 100%;
	}

	section.weather-plotting .holder .head p {
		float: none;
		text-align: left;
	}

	.copy .right a {
		font-size: 16px;
		left: 0;
		color: #272a3b;
		position: relative;
		margin-bottom: 30px;
	}

	.copy .right .dispach {
		font-size: 16px;
		right: 0px;
		color: #272a3b;
		position: relative;
		top: auto;
		display: block;
	}

	.copy .right .dispach p {
		font-size: 16px;
		top: 0;
		margin-top: 1px;
		color: #272a3b;
		width: 100%;
		position: relative;
		left: auto;
	}

	.copy .right i#weather {
		font-size: 50px;
		color: #272a3b;
	}

	header .copy.main,
	article {
		text-align: left;
	}

	header .copy h1,
	header .copy h1,
	header .copy h3,
	header .copy p {
		color: #272a3b;
	}

	.copy.main h2,
	article h2,
	section.search .holder h2,
	header .copy h1 {
		font-size: 28px;
		// line-height: 28px;
		margin-bottom: 10px;
	}

	.copy.main h3,
	article h3,
	section.search .holder h3,
	section.search .holder span,
	section.search .holder h4,
	header .copy h3,
	header .copy h4 {
		font-size: 18px;
		line-height: 22px;
		margin-bottom: 10px;
	}

	.copy.main p,
	article p,
	section.search .holder p,
	header .copy p {
		font-size: 14px;
		line-height: 18px;
		margin-bottom: 10px;
	}

	section.search {
		padding: 0px 15px;
	}

	.copy .left,
	.copy .right {
		width: 100%;
		padding: 5%;
	}

	article.col-2 div iframe {
		height: 215px;
	}

	article span {
		font-size: 14px;
	}

	.burger-wrap h2 {
		display: none;
	}

	header {
		background: white !important;
		height: auto;
	}

	header .copy a {
		color: #272a3b;
		width: 100%;
		display: block;
	}

	header .copy.main {
		padding: 0;
	}

	header .holder.main {
		margin-top: 0;
		padding: 100px 20px 40px 20px;
		top: 0;
		transform: none;
		-webkit-transform: none;
		-moz-transform: none;
		-ms-transform: none;
	}

	.copy.main h2,
	.copy.main h3,
	.copy.main p {
		color: #272a3b;
	}

	header .buttons {
		display: none;
	}

	header .copy.js-scroll-down {
		display: none;
	}

	header .copy.js-scroll-down i {
		color: $primary2;
	}

	article.col-2 div.image {
		min-height: 41%;
		// height: auto;
	}

	section.contact .holder .contact-item {
		width: 100%;
	}

	section.contact .holder .contact-item h2 {
		font-size: 21px;
	}
	/*about*/
	section.about {
		padding: 0;
	}

	section.about article {
		background: #575675;
		color: white;
		padding: 30px;
	}

	section.about article h1,
	section.about article h2,
	section.about article h3,
	section.about article p {
		color: white;
	}

	section.about article h1 {
		font-size: 22px;
		line-height: 28px;
		margin-bottom: 10px;
	}

	section.about article h2 {
		// font-size: 20px;
		// line-height: 26px;
		margin-bottom: 10px;
	}

	section.about article h3 {
		font-size: 18px;
		line-height: 24px;
		margin-bottom: 10px;
	}

	section.about article p {
		font-size: 14px;
		line-height: 18px;
		margin-bottom: 10px;
	}
	/*weather*/
	section.weather h2,
	section.weather .weather-wrap .weather-item span,
	section.weather-plotting .holder .head h2 {
		font-size: 26px;
		margin-bottom: 10px;
	}

	section.weather-plotting .holder .plotter .plot-item {
		width: 100%;
	}

	section.weather .weather-wrap .weather-item {
		width: 100%;
		margin-bottom: 15px;
	}

	section.weather-plotting {
		padding-left: 20px;
		padding-right: 20px;
		padding-top: 24px;
		padding-bottom: 0;
	}

	section.weather-plotting .holder .plotter {
		padding: 50px 0px 50px 0px;
	}

	section.weather-plotting .holder .head {
		padding: 0px 0 50px 0;
	}

	.weather-item .heading-lg {
		font-size: 18px;
	}
	.plot-area {
		padding: 0 20px 5px 20px;
	}

	.button.hide-graph {
		width: 100%;
		margin-bottom: 15px;
	}

	.mobile-search input {
		width: 200px;
		height: 40px;
		border: none;
		padding-left: 10px;
	}

	.mobile-search {
		position: absolute;
		top: -31px;
		padding: 40px 0 0 10px;
	}

	section.contact {
		background: #575675;
		padding-bottom: 20px;
	}

	section.contact .holder {
		padding: 40px 20px;
	}

	section.contact .holder .contact-item h2,
	section.contact .holder .contact-item h3,
	section.contact .holder .contact-item p {
		color: #fff;
	}
}

@media all and (max-width: 330px) {
	article.col-2 div iframe {
		height: 180px;
	}
}

//IE8?!
body.ie8 section.weather-plotting {
	display: none;
}

body.ie8 .hideifie {
	display: none;
}

body.ie8 .showifie {
	display: block;
}

body.ie8 .map {
	display: none;
}

body.ie8 .overview {
	display: block;
}

body.ie8 header.pg {
	padding-top: 10%;
}

body.ie8 header .buttons span {
	background: $primary2;
}

body.ie8 .burger-wrap h2 {
	color: #fff;
}

body.ie8 .main.holder {
	top: 25%;
}

//NEW MARKNADSAVDELNING STYLE
p,
h3 {
	font-weight: 400;
}

.overlay-blue {
	background: $marknadsBlue;
	// opacity: 0.3;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
}

.form-holder {
	padding: 30px 0;
	margin: 0 auto;
	max-width: 500px;
	width: 100%;
	overflow: hidden;

	iframe {
		height: 700px;
		width: 400px;
		max-width: 100%;
		;
	}
}
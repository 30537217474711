header nav.fixed .logo {
	left: 25px;
}

header nav {
	height: 95px;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	z-index: 4;
	background: rgba(0, 99, 163, 0.4);
	transition: 0.2s all;
}

header nav.solid,
.search-head nav {
	background: rgba(0, 99, 163, 1);
}

header nav a,
header nav i {
	color: rgba(255, 255, 255, 0.5);
	cursor: pointer;
	-webkit-transition: 0.2s all ease-in-out;
	-moz-transition: 0.2s all ease-in-out;
	-o-transition: 0.2s all ease-in-out;
	transition: 0.2s all ease-in-out;
}

header nav a:hover,
header nav i:hover {
	color: rgba(255, 255, 255, 1);
}

header nav ul {
	text-align: right;
	padding: 40px 0 40px 0;
	position: absolute;
	right: 100px;
}

header nav ul li {
	display: inline-block;
	margin-right: 30px;
}

// Sub menu
header nav.has-sub ul {
	right: 61px;
}

header nav .sub-menu {
	background: transparent;
	display: none;
	height: 50px;
	width: 100%;
}

// header nav.sub.layer-3.solid {
//     background: rgba(39, 42, 59, 1);
// }

header nav.has-sub ul {
	padding-top: 20px;
	padding-bottom: 15px;
	padding-left: 20px;
	margin-right: 38px;
	border-bottom: 1px solid #0c4b74;
}

header nav.has-sub ul nav.sub ul {
	border-bottom: none;
	padding: 25px 0px 0px 0px;
	right: 61px;
}

header nav.has-sub ul nav.sub ul a {
	font-size: 14px;
}

header nav.sub.layer-3 {
	background: rgba(39, 42, 59, 0.4);
	margin-top: 10px;
	height: 40px;
}

header nav.sub.layer-3 ul.layer-3 {
	padding: 10px 0px;
}

// Active
header nav.has-sub ul nav.sub ul a.active {
	padding-bottom: 0;
	border-bottom: 0;
}

header nav .current_page_item.menu-item-has-children .sub-menu {
	display: block;
}

header nav .current_page_item a {
	font-weight: 500;
	color: rgba(255, 255, 255, 1);
}

header nav.has-sub a.active {
	font-weight: 500;
	color: rgba(255, 255, 255, 1);
	padding-bottom: 15px;
	border-bottom: 1px solid $primary2;
}

/*proving ground map specific styling*/
@media all and (max-width: 1650px) {
	header nav a {
		font-size: 14px;
	}
}

@media all and (max-width: 1500px) {
	header nav a {
		font-size: 13px;
	}

	header nav ul li {
		margin-right: 20px;
	}
}

@media all and (max-width: 1400px) {
	header nav ul li {
		margin-right: 10px;
	}
}
/*Tablet*/
@media all and (max-width: 1285px) {
	header nav a {
		font-size: 16px;
	}
}

/*Tablet*/
@media all and (max-width: 1285px) {
	header .copy {
		// padding: 0 8%;
	}

	header .copy.js-scroll-down {
		padding: 5px 0px 0px 0px;
	}

	.flex {
		display: block;
	}

	article.col-2 {
		position: relative;
		background: rgba(50, 53, 65, 1);
		width: 100%;
	}

	article.col-2 div {
		min-width: 100%;
	}

	.copy.main {
		// padding: 0 8%;
	}

	article.col-2 div.copy {
		padding: 5%;
		color: #fff;
		position: relative;
		z-index: 1;
		background: $marknadsBlue;
	}

	article.col-2 div.image.image-right {
		order: initial;
	}

	section.search {
		padding: 50px;
	}

	article.col-2 div.image {
		height: 578px;
	}

	article h2 {
		color: #fff;
		margin-bottom: 10px;
	}

	article h3 {
		color: #fff;
		margin-bottom: 10px;
	}

	article p {
		color: #fff;
		margin-bottom: 10px;
	}

	header nav {
		width: 100%;
		height: 60px;
		background: #272a3b;
		left: 0;
		right: 0;
		border-bottom: none !important;
	}

	header nav:hover,
	header nav.active {
		border: none;
		background: #272a3b;
	}

	header nav.fixed {
		background: #272a3b;
		border: none;
	}

	#nav-toggle {
		position: absolute;
		right: 13px;
		top: 10px;
	}

	#nav-toggle {
		cursor: pointer;
		padding: 10px 35px 16px 0px;
	}

	#nav-toggle span,
	#nav-toggle span:before,
	#nav-toggle span:after {
		cursor: pointer;
		border-radius: 1px;
		height: 2px;
		width: 20px;
		background: #fff;
		position: absolute;
		display: block;
		content: '';
	}

	#nav-toggle span:before {
		top: -7px;
	}

	#nav-toggle span:after {
		bottom: -7px;
	}

	section.contact .holder .contact-item {
		width: 44%;
		margin: 20px !important;
	}

	#nav-toggle span,
	#nav-toggle span:before,
	#nav-toggle span:after {
		transition: all 300ms ease-in-out;
		-webkit-transition: all 300ms ease-in-out;
		-moz-transition: all 300ms ease-in-out;
		-o-transition: all 300ms ease-in-out;
	}

	#nav-toggle.active span {
		background-color: transparent;
	}

	#nav-toggle.active span:before,
	#nav-toggle.active span:after {
		top: 0;
		background: #fff;
	}

	#nav-toggle.active span:before {
		transform: rotate(45deg);
		-webkit-transform: rotate(45deg);
		-moz-transform: rotate(45deg);
		-o-transform: rotate(45deg);
	}

	#nav-toggle.active span:after {
		transform: rotate(-45deg);
		-webkit-transform: rotate(-45deg);
		-moz-transform: rotate(-45deg);
		-o-transform: rotate(-45deg);
	}

	header nav:hover i.icon-search {
		color: #272a3b;
	}

	header nav ul,
	header nav.has-sub ul {
		width: 300px;
		display: block;
		background: $marknadsBlue;
		right: -300px;
		margin-right: 0;
		padding-left: 0;
		text-align: left;
		position: fixed;
		top: 0;
		padding-top: 60px;
		height: 100%;
		-webkit-transition: 0.3s all ease-in-out;
		-moz-transition: 0.3s all ease-in-out;
		-o-transition: 0.3s all ease-in-out;
		transition: 0.3s all ease-in-out;
	}

	header nav.has-sub ul nav.sub ul {
		right: 0;
	}

	header nav ul.toggled {
		right: 0;
		overflow: hidden;
	}

	header nav a {
		border-bottom: none !important;
	}

	header ul {
		border-bottom: none !important;
	}

	header nav ul li {
		width: 100%;
		padding: 8px 0;
		padding-left: 30px;
		border-top: 1px solid rgba(255, 255, 255, 0.1);
	}

	header nav ul li:hover {
		background: rgba(0, 0, 0, 0.1);
	}

	header nav ul li#pg:hover ul li,
	header nav ul li#pg:hover {
		background: none;
	}

	header nav ul li#pg {
		position: relative;
	}

	header nav ul li a {
		padding: 0;
		border: none;
		position: relative;
	}

	header nav ul li a:hover {
		border: none;
		text-decoration: none;
	}

	nav:hover ul {
		border: none;
	}

	nav li:hover a {
		color: rgba(255, 255, 255, 1) !important;
	}

	header li#pg nav.sub {
		visibility: visible;
		opacity: 1;
		height: auto;
		position: relative;
		top: 0;
	}

	header li#pg nav.sub ul {
		background: transparent;
		position: relative;
		top: 0;
		width: 100%;
		height: auto;
		overflow: initial;
		border: none;
		padding: 10px 0;
	}

	header li#pg nav.sub ul li {
		border: none;
		padding-left: 0;
	}

	header li#pg nav.sub ul li a {
		font-size: 14px;
		padding: 0;
		border: none;
	}

	header li#pg nav.sub:hover ul {
		border-bottom: none !important;
	}

	header nav a.active {
		border-bottom: none !important;
		position: relative;
	}

	header nav a:hover {
		margin-left: 10px !important;
	}

	header nav a:hover:before {
		width: 4px;
	}

	header nav a:before {
		content: '';
		position: absolute;
		height: 36px;
		width: 0px;
		top: -8px;
		left: -40px;
		background: #fff;
		transition: 0.2s all;
	}

	.js-toggle-search {
		display: none;
	}

	.search-overlay {
		display: none;
	}
}

/*Mobile*/
@media all and (max-width: 500px) {
	header nav ul {
		width: 100% !important;
		right: -100% !important;
	}
	header nav ul.toggled {
		right: 0 !important;
	}
}

@media all and (max-width: 330px) {
	header nav ul li {
		padding: 6px 0;
		padding-left: 30px;
	}

	header li#pg nav.sub ul {
		padding: 0px;
	}
}

//IE8?!
body.ie8 nav li a {
	color: white !important;
}

body.ie8 header nav.sub {
	opacity: 1;
	visibility: visible;
}

.big-image-text-grid {
	&__column {
		width: 100%;
		background: #fff;
		padding: 116px 0;
		display: flex;
		padding: 0;
		min-height: 560px;

		&.odd {
			.big-image-text-grid__column-image {
				order: 2;
			}

			.big-image-text-grid__column-inner {
				order: 1;
			}
		}

		@media screen and (max-width: 868px) {
			min-height: none;
			flex-direction: column;

			&.odd {
				.big-image-text-grid__column-image {
					order: 1;
				}

				.big-image-text-grid__column-inner {
					order: 2;
				}
			}
		}

		&-image {
			width: 50%;
			position: relative;

			@media screen and (max-width: 868px) {
				width: 100%;
			}

			img {
				max-width: 100%;
				display: block;
				object-fit: cover;
				position: absolute;
				width: 100%;
				height: 100%;

				@media screen and (max-width: 868px) {
					object-fit: contain;
					position: relative;
					width: auto;
					height: auto;
				}
			}
		}

		&-inner {
			width: 50%;
			padding: 116px 5%;
			display: flex;
			align-items: center;
			justify-content: center;

			@media screen and (max-width: 868px) {
				width: 100%;
				padding: 48px 24px;
				justify-content: flex-start;
			}
		}

		&-content {
			width: 100%;
			max-width: 620px;

			&.has-subtitle {
				h2 {
					margin-bottom: 16px;

					@media screen and (max-width: 640px) {
						margin-bottom: 16px;
					}
				}
			}

			h2 {
				font-size: $textXL;
				margin-bottom: 32px;

				@media screen and (max-width: 640px) {
					font-size: $textL;
					margin-bottom: 24px;
				}
			}

			h3 {
				font-size: $textS;
				line-height: 1.5rem;
				font-weight: 600;
				margin-bottom: 16px;
			}

			p {
				font-size: $textS;
				line-height: 1.5rem;
			}
		}

		.button {
			margin-top: 32px;

			@media screen and (max-width: 640px) {
				margin-top: 24px;
			}
		}
	}
}
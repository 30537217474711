.three-column-block {
	width: 100%;
	padding-top: 110px;
	padding-bottom: 110px;
	background: $lighestGray;
	color: #000;

	@media screen and (max-width: 500px) {
		padding-top: 42px;
		padding-bottom: 42px;
	}

	&__inner {
		width: 100%;

		& .heading-xl {
			margin: 0 auto 24px auto;
		}

		// & .heading-md {
		// 	max-width: 520px;
		// 	margin: 0 auto;
		// }

		.button {
			margin-top: 76px;
		}
	}

	&__columns {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		margin: 0 -30px;

		@media screen and (max-width: 500px) {
			margin: 0;
		}
	}

	&__column {
		width: calc(100% / 3 - 60px);
		margin: 30px;

		@media screen and (max-width: 868px) {
			max-width: none;
			width: calc(100% / 2 - 60px);
		}

		@media screen and (max-width: 500px) {
			width: 100%;
			margin: 30px 0 20px 0;
		}

		& .heading-md {
			margin-bottom: 16px;
		}
	}
}

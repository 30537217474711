.text-button-block {
	width: 100%;

	padding: 116px 0;

	&.bg-white {
		background: $white;
	}
	&.bg-beige {
		background: $lighestGray;
	}
	@media screen and (max-width: 640px) {
		padding: 68px 0;
	}

	&--has-image {
		display: flex;
		padding: 0;
		min-height: 560px;

		@media screen and (max-width: 868px) {
			min-height: none;
			flex-direction: column;
		}

		.text-button-block__inner {
			width: 50%;
			padding: 116px 5%;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;

			@media screen and (max-width: 868px) {
				width: 100%;
				padding: 48px 24px;
			}

			.text-button-block__content {
				padding: 0;
				text-align: left;
			}

			.button {
				margin-top: 32px;

				@media screen and (max-width: 640px) {
					margin-top: 24px;
				}
			}
		}
	}

	&__image {
		width: 50%;
		position: relative;

		@media screen and (max-width: 868px) {
			width: 100%;
		}

		img {
			max-width: 100%;
			display: block;
			object-fit: cover;
			position: absolute;
			width: 100%;
			height: 100%;

			@media screen and (max-width: 868px) {
				object-fit: contain;
				position: relative;
				width: auto;
				height: auto;
			}
		}
	}

	&__inner {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;

		&--center-text {
			justify-content: center;
			align-items: center;
			flex-direction: column;

			.text-button-block__content {
				text-align: center;
				padding: 0;
			}

			.button {
				margin-top: 32px;

				@media screen and (max-width: 640px) {
					margin-top: 24px;
				}
			}
		}

		@media screen and (max-width: 868px) {
			flex-direction: column;
		}
	}

	&__content {
		width: 100%;
		max-width: 704px;
		padding: 0 32px 0 0;

		@media screen and (max-width: 1024px) {
			max-width: 620px;
		}

		@media screen and (max-width: 868px) {
			text-align: center;
			padding: 0;
		}

		&.has-subtitle {
			h2 {
				margin-bottom: 16px;

				@media screen and (max-width: 640px) {
					margin-bottom: 16px;
				}
			}
		}

		h2 {
			font-size: $textXL;
			margin-bottom: 32px;

			@media screen and (max-width: 640px) {
				font-size: $textL;
				margin-bottom: 24px;
			}
		}

		h3 {
			font-size: $textS;
			line-height: 1.5rem;
			font-weight: 600;
			margin-bottom: 16px;
		}

		p {
			font-size: $textS;
			line-height: 1.5rem;
		}
	}

	.button {
		@media screen and (max-width: 868px) {
			margin-top: 32px;
		}
	}
}

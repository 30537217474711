.proving-grounds-block {
	margin-top: 77px;
	margin-bottom: 77px;

	@media screen and (max-width: 600px) {
		margin-top: 40px;
		margin-bottom: 40px;
	}

	& .heading-xl {
		margin-bottom: 48px;

		@media screen and (max-width: 600px) {
			margin-bottom: 36px;
		}
	}

	&__wrap {
		display: flex;
		flex-wrap: wrap;
	}
}

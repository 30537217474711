.small-columns {
    width: 100%;
    max-width: 1200px;
    margin: 100px auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;

    @include below(1024px) {
        margin: 50px auto;
    }

    &__column {
        width: calc(100% / 3);
        max-width: calc(100% / 3);
        padding: 10px;

        @include below(769px) {
            width: 50%;
            max-width: 50%;
        }

        @include below(500px) {
            width: 100%;
            max-width: 100%;
        }

        &-inner {
            -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.04);
            -moz-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.04);
            box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.04);
        }

        &-content {
            padding: 20px;
        }

        &-image {
            max-width: 100%;
            display: block;
            transition: opacity .2s ease-in-out;
        }

        &-title {
            font-size: 24px;
            line-height: 30px;
            font-family:'bebas-neue', sans-serif;
            text-transform: uppercase;
            margin: 20px 0 15px 0;
        }

        &-link {
            display: block;
            margin: 20px 0 0 0;
            color: #ffff;
            font-size: 18px;
            padding: 15px;
            background: #0063a3;
            text-align: center;
            flex-direction: 20px;
            // font-family: 'bebas-neue', sans-serif;
            font-family: "Aller",sans-serif;
            transition: background .2s ease-in-out;

            &:hover {
                background: rgba($marknadsBlue, 0.7);
            }
        }
    }
}
